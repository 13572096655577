<template>
  <div class="plan">
      <h4 class="plan-name">{{ planData.name }}</h4>
      <v-row>
        <br/>
        <div v-if="planData.oldpriceCLP">
        <!--<div class="offer">¡OFERTA!</div>-->
        <div class="ribbon">
        <div class="ribbon-stitches-top"></div>
        <div class="ribbon-content" >
          <h1><b>CYBER 20%DCTO.</b></h1>
        </div>
        <div class="ribbon-stitches-bottom"></div>
      </div>
      </div>
      </v-row>
      <v-row style="flex: none;">
          <div v-if="planData.oldpriceCLP">
          <div class="priceCLPdesc">{{ formatPrice(planData.oldpriceCLP) }}</div>
          <div class="priceCLP">{{ formatPrice(planData.priceCLP) }}</div>
          <div class="positionCLPdesc">
            <div>CLP /</div>
            <div>MES</div>
          </div>
          </div>
          <div v-else>
          <div class="priceCLP">{{ formatPrice(planData.priceCLP) }}</div>
          <div class="positionCLP">
            <div>CLP /</div>
            <div>MES</div>
          </div>
          </div>
          <!--<v-col class="px-1">
            <div>CLP /</div>
            <div>MES</div>
          </v-col>-->
      </v-row>
      <v-row style="flex: none;" >
          <span>UF {{ ufchange(planData.price) }}</span>
     </v-row>
     <v-col style="display: flex; flex-direction: column; align-items: center;">
        <h3>¿Qué cubre?</h3>
        <div class="cover" v-for="pl in planData.coverage" :key="pl._id">
            <div class="desc">{{pl.description}}</div>
            <!-- <div class="amount" v-if="cover.amount">UF {{cover.amount}}</div>     -->
        </div>
    </v-col>   
    <div style="height: 1rem;"></div>    
     <v-btn color="primary" @click="validate()"> ¡Lo quiero! </v-btn>
  </div>
  
</template>

<script>
export default {
  name: "Plan",
  props: ["planData"],
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
  }),
  methods: {
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf){
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    validate() {
      this.$emit("next-step", this.planData);
    },
    parseNumber(n) {
      return new Intl.NumberFormat().format(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.priceCLPdesc {
      color: #f52222;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;
    text-decoration-line: line-through;
    margin: 0 auto;
  }
  .offer {
      color: #f00000e0;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;
    
  }
  .positionCLPdesc {
    text-align:end;
    position: relative;
    left: 35px;
    top: -60px;
  }
  .positionCLP {
    text-align:end;
    position: relative;
    left: 40px;
    top: -60px;
  }

//Cinta 20%DCTO.
.ribbon {
     position: relative;
     left: 30x;
     top: 10px;
     width: 248px;
     text-align: center;
     font-size: 15px !important;
     background: #f52222;
     background: -webkit-gradient(linear, left top, left bottom, from(#f52222), to(#AB2C2C));
     background: -webkit-linear-gradient(top, #f52222, #AB2C2C);
     background: -moz-linear-gradient(top, #f52222, #AB2C2C);
     background: -ms-linear-gradient(top, #f52222, #AB2C2C);
     background: -o-linear-gradient(top, #f52222, #AB2C2C);
     background-image: -ms-linear-gradient(top, #f52222 0%, #AB2C2C 100%);
     -webkit-box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
     -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
     box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
     font-family: 'Helvetica Neue',Helvetica, sans-serif;
}

.ribbon h1 {
     text-align: center;
     font-size: 25px !important;
     color: #ffffff;
     text-shadow: #D65C5C 0 1px 0;
     margin:0px;
     padding: 12px 10px;
}

.ribbon:before, .ribbon:after {
     content: '';
     position: absolute;
     display: block;
     bottom: -1em;
     border: 1.5em solid #C23A3A;
     z-index: -1;
}

.ribbon:before {
     left: -2em;
     border-right-width: 1.5em;
     border-left-color: transparent;
     -webkit-box-shadow: rgba(0,0,0,0.4) 1px 1px 1px;
     -moz-box-shadow: rgba(0,0,0,0.4) 1px 1px 1px;
     box-shadow: rgba(0,0,0,0.4) 1px 1px 1px;
}

.ribbon:after {
     right: -2em;
     border-left-width: 1.5em;
     border-right-color: transparent;
     -webkit-box-shadow: rgba(0,0,0,0.4) -1px 1px 1px;
     -moz-box-shadow: rgba(0,0,0,0.4) -1px 1px 1px;
     box-shadow: rgba(0,0,0,0.4) -1px 1px 1px;
}

.ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
     border-color: #871616 transparent transparent transparent;
     position: absolute;
     display: block;
     border-style: solid;
     bottom: -1em;
     content: '';
}

.ribbon .ribbon-content:before {
     left: 0;
     border-width: 1em 0 0 1em;
}

.ribbon .ribbon-content:after {
     right: 0;
     border-width: 1em 1em 0 0;
}

.ribbon-stitches-top {
     margin-top:2px;
     border-top: 1px dashed rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
     -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
     box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
}

.ribbon-stitches-bottom {
     margin-bottom:2px;
     border-top: 1px dashed rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
     -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
     box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.3);
}
//HASTA AQUI

.plan {
  background-color: #ffffff;
  border: 1px solid #41b0e7;
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  height: fit-content;

  h4 {
    &.plan-name {
      text-transform: uppercase;
      color: #41b0e7;
      letter-spacing: 0.3rem;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .priceCLP {
      color: #00263E;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 3px;
  }

  .cover {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #41b0e7;
      align-items: center;
      justify-content: center;

      .desc {
          text-align: center;
      }

      .amount {
          color: #41b0e7;
          font-weight: 600;
      }
  }

  .cover:last-child {
      border-bottom: none;
  }
}
</style>